import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {ModalContext} from "../../contexts";
import React, {useContext} from "react";

const BaseModal = (props) => {
  const { closeModal } = useContext(ModalContext);

  const toggleModalHandler = () => {
    closeModal();
  }

  return (
    <Modal
      isOpen={true}
      toggle={toggleModalHandler}
      size={props.size || 'md'}
    >
      <ModalHeader toggle={toggleModalHandler}>{props.title}</ModalHeader>
      <ModalBody>
        {props.content}
      </ModalBody>
    </Modal>
  );
}

export default BaseModal;
